import { postFormData } from './../Atoms/fetchWraps'
import { Notification } from './../classes/Notification'
import { ModalDoT } from './../classes/Modal-doT'


const loginForm = document.getElementById("loginForm")

loginForm.addEventListener("submit", e => {
  e.preventDefault()

  postFormData('/api/auth', loginForm)
  .then(res => {
    if (res.contents) {
      window.location.href = '/dashboard'
    } else {
      const notif = new Notification({message: "Couple email/mot de passe inconnu"})
      notif.display()
    }
  })
})

loginForm.querySelector(".icon-off svg").addEventListener("click", e => {
  loginForm.querySelector('[name="password"]').setAttribute("type", "text")
  loginForm.querySelector(".input-group.password").classList.add("show")
})

loginForm.querySelector(".icon-on svg").addEventListener("click", e => {
  loginForm.querySelector('[name="password"]').setAttribute("type", "password")
  loginForm.querySelector(".input-group.password").classList.remove("show")
})

loginForm.querySelector(".openModalReset").addEventListener("click", e => {
  e.preventDefault()

  const args = {
    templateHTMLstr: document.getElementById("resetPassword").innerHTML
  }
  const resetModal = new ModalDoT(args)
  resetModal.display()

  document.querySelector(".modal-wrap.resetPassword form").addEventListener("submit", e => {
    e.preventDefault()
  
    postFormData('/reset-password', e.target)
    .then(res => {

      if (res.status === "success") {
        const notif = new Notification({message: "Demande envoyée"})
        notif.display()

      } else {
        const notif = new Notification({message: "Votre demande n'a pas pu être envoyée"})
        notif.display()
      }

      setTimeout(() => {
        resetModal.dismiss()
      }, 200)
    })
  })
})