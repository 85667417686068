class Notification {

	constructor(opts) {
  this.autoDismiss = true
  this.delay = 3000  // milliseconds
  this.message = "Notification"
  this.class = "notif"
  this.parentID = "warningLayer"

  /* Overriding defaults if specified */
  if (typeof opts !== "object" && opts) {
   console.warn(`[Notification class] Invalid options object`)
  } else {
   for (const key in opts) {
    if ((typeof opts[key] !== typeof this[key])) {
     console.warn(`[Notification class] Option "${key}" given is invalid`)
     continue
    }
    this[key] = opts[key]
   }
  }
  this.element = this.#createElement()
 }

 #createElement() {
  var newEl = document.createElement("div")
  var newContent = document.createTextNode(this.message)
  newEl.appendChild(newContent)
  newEl.classList.add(this.class)

  if (document.getElementById(this.parentID)) {
   document.getElementById(this.parentID).appendChild(newEl)
  } else {
   console.error("Notification class: No specific parent div has been set ('warningLayer' by default)")
  }
  return newEl
 }

 setMessage(msg) {
  this.element.innerText = msg
 }

 dismiss() {this.element.classList.remove("active")}

 display() {
  this.element.classList.add("active")

  if (this.autoDismiss) {
   setTimeout(()=>{this.dismiss()}, this.delay)
  }
 }
}

export { Notification }