import * as doT from './../Atoms/doT.min.js'

class ModalDoT {

	constructor(opts) {
  this.templateHTMLstr = "<div>Modal</div>"
  this.parentID = "warningLayer"
  this.activeClass = "active" // If changed, can mess with display()
  this.closeClass = "closeEvent"
  this.data = null
  this.events = {}

  /* Overriding defaults if specified */
  for (const key in opts) {
   if ((typeof opts[key] !== typeof this[key]) || (!opts[key])) {
    console.warn(`[Modal class] Option "${key}" given is invalid or empty`)
    continue
   }
   this[key] = opts[key]
  }

  /* Initializing modal */
  this.templateFn = doT.template(this.templateHTMLstr)
  this.element = this.#createElement()
 }

 #createElement() {
  let tempWrap = document.createElement("div")

  /* Filling data */
  tempWrap.innerHTML = this.templateFn(this.data)

  /* Activating functions based on events */
  if (this.events) {
   Array.from(tempWrap.querySelectorAll("[data-event]")).forEach(el => {
    let eventCode = el.getAttribute("data-event").split(";")
    el.addEventListener(eventCode[0], this.events[eventCode[1]])
   })
  }

  /* Adding EventListeners for closing */
  if (tempWrap.querySelector("."+this.closeClass)) {
   Array.from(tempWrap.querySelectorAll("."+this.closeClass)).forEach((el) => el.addEventListener("click", e=>{this.dismiss()}))
  }

  let newModal = tempWrap.firstElementChild

  /* Inserting inside the right parent */
  if (document.getElementById(this.parentID)) {
   document.getElementById(this.parentID).appendChild(newModal)
  } else {
   console.error("Modal class: No specific parent div has been set ('warningLayer' by default)")
  }

  // newEl.addEventListener("click", e=>{
  //  /* Preventing clicks on children */
  //  if (e.target.id.includes(this.templateID)){this.dismiss()}
  // })

  return newModal
 }

 dismiss() {
  this.element.classList.remove(this.activeClass)
  document.getElementById(this.parentID).classList.remove("active")
 }

 display() {
  /* Deactivating all others modals */
  Array.from(document.getElementById(this.parentID).querySelectorAll("."+this.activeClass)).map(el => {el.classList.remove(this.activeClass)})

  this.element.classList.add(this.activeClass)
  document.getElementById(this.parentID).classList.add("active")
 }

}

export { ModalDoT }