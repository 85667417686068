async function getJSON(url, fetchArgs) 
{
 const args = Object.assign({method: 'GET'}, fetchArgs)

 try {
  const res = await fetch(url, args)
  if (!res.ok) {
   const message = `getJSON: an error has occured (${res.status})`;
   throw new Error(message);
  }
  const data = await res.json()
  return data

 } catch(err) {
  throw new Error(`[getJSON]: Fetch failed with url: ${url}`)
 }
}


async function postFormData(url, formEl, fetchArgs) 
{
 /* Not using FormData directly as it is sent has 'multipart/form-data' */

 const urlencoded = new URLSearchParams()

 for (const pair of new FormData(formEl)) {
  urlencoded.append(pair[0], pair[1])
 }

 const args = Object.assign({
  method: 'POST',
  body: urlencoded,
  headers: {"Content-Type": "application/x-www-form-urlencoded"},
  redirect: 'follow'
 }, fetchArgs)

 // try {
  const res = await fetch(url, args)
  if (!res.ok) {
   const message = `[postFormData]: an error has occured (${res.status})`;
   throw new Error(message);
  }
  const data = await res.json()
  return data

 // } catch(err) {
 //  throw new Error(`[postFormData]: Fetch failed with url: ${url} and data: ${data}`)
 // }
}


async function uploadImage(url, formEl, event) {
 /* Not using FormData directly as it is sent has 'multipart/form-data' */
 const dataForm = new FormData()

 for (const pair of new FormData(formEl)) {
  dataForm.append(pair[0], pair[1])
 }
 dataForm.set('file', event.target.files[0], event.target.files[0].name)

 const args = {
  method: 'POST',
  body: dataForm
  // No header content-type here, not working
 }

 // try {
  const res = await fetch(url, args)
  if (!res.ok) {
   const message = `[uploadImage]: an error has occured (${res.status})`;
   throw new Error(message);
  }
  const data = await res.json()
  return data

 // } catch(err) {
 //  throw new Error(`[postFormData]: Fetch failed with url: ${url} and data: ${data}`)
 // }
}


async function uploadFile(url, formEl, event) 
{
  const dataForm = new FormData()
  const uploadFileEl = formEl.querySelector('[name="file_to_import"]')
 
  dataForm.set('file', uploadFileEl.files[0], uploadFileEl.files[0].name)
 
  const args = {
   method: 'POST',
   body: dataForm
   // No header content-type here, not working
  }
 
  // try {
   const res = await fetch(url, args)
   if (!res.ok) {
    const message = `[uploadFile]: an error has occured (${res.status})`;
    throw new Error(message);
   }
   const data = await res.json()
   return data
 
  // } catch(err) {
  //  throw new Error(`[postFormData]: Fetch failed with url: ${url} and data: ${data}`)
  // }
 }


async function getPublicPrice(price) {

  const dataForm = new FormData()
  dataForm.set('price', price)
 
  const args = {
   method: 'POST',
   body: dataForm
  }
 
  const res = await fetch('/compute-public-price', args)

  if (!res.ok) {
    const message = `[getPublicPrice]: an error has occured (${res.status})`;
    throw new Error(message);
  }

  const data = await res.json()
  return data
 }


async function deleteRecord(url) 
{
 const args = {method: 'DELETE'}

 try {
  const res = await fetch(url, args)
  if (!res.ok) {
   const message = `[deleteRecord]: an error has occured (${res.status})`;
   throw new Error(message);
  }
  const data = await res.json()
  return data

 } catch(err) {
  throw new Error(`[deleteRecord]: Fetch failed with url: ${url}`)
 }
}


async function downloadFile(url) {
//  console.log(url)
 const entity = url.split("/")[2]

 const res = await fetch(url)
 if (!res.ok) {
  const message = `[downloadFile]: an error has occured (${res.status})`;
  throw new Error(message);
 }
 const blob = await res.blob()

 const downloadURL = window.URL.createObjectURL(blob)
 var a = document.createElement('a');
 a.href = downloadURL;
 a.download = entity+".csv";
 document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
 a.click();    
 a.remove();  //afterwards we remove the element again  

 /* RANDOM file */
 // const file = window.URL.createObjectURL(blob)
 // window.location.assign(file)
}

export {
 getJSON,
 postFormData,
 getPublicPrice,
 deleteRecord,
 downloadFile,
 uploadImage,
 uploadFile
}